import React from 'react';
import Modal from '~/components/Generic/Modal';
import { Button } from 'theme-ui';
import Close from '~/assets/images/icons/close.svg';
import AmbassadorVideo from './ambassadorVideo';
import toSnakeCase from '~/utils/to-snake-case';

const VideoModal = ({
  videoModal,
  setVideoModal,
  videoRef,
  setVideoPlaying,
  playerRef,
  mainVideo,
  videoPlaying,
  forwardSx,
  locationDetail,
  aspectRatio,
}) => {
  return (
    <Modal
      forwardSx={{ borderRadius: 0 }}
      isOpen={videoModal}
      onDismiss={() => setVideoModal(false)}
      ariaLabel="Promotional video for ambassador"
    >
      <Button
        onClick={() => {
          if (videoRef.current) {
            videoRef.current?.pause();
            setVideoPlaying(false);
            setVideoModal(false);
          }
        }}
        variant="bare"
        sx={{
          '--strokecolor': 'black',
          position: 'absolute',
          top: '10px',
          right: '10px',
          zIndex: 1,
          transition: 'opacity 0.3s ease',
          outline: 'none',
          filter: 'drop-shadow(0 0 0.04rem white)',
        }}
      >
        <Close sx={{ width: '25px', height: '25px' }} />
      </Button>
      <AmbassadorVideo
        locationDetail={locationDetail}
        aspectRatio={aspectRatio}
        forwardSx={{ ...forwardSx }}
        playerRef={playerRef}
        videoRef={videoRef}
        mainVideo={mainVideo}
        videoPlaying={videoPlaying}
        setVideoPlaying={setVideoPlaying}
        videoLabel={mainVideo?.name && toSnakeCase(mainVideo.name)}
      />
    </Modal>
  );
};

export default VideoModal;
