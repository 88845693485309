import { Box, Flex, Text } from 'theme-ui';
import { useRef, useState } from 'react';
import ImgLoader from '~/components/Generic/ImgLoader';
import VideoModal from '~/components/Ambassadors/Featured/VideoModal';
import { useCustomAnalytics } from '~/hooks/utils/customAnalytics';
import CTAButton from '~/components/Generic/CTAButton';
import StarRating from './StarRating';

type Props = {
  card: Queries.AmbassadorCardFragmentFragment;
};
const TestimonialCard = ({ card }: Props) => {
  const { name, tagline, bio, photo, mainVideo, buttonText, backgroundColor, starRating, bioTitle } = card;
  const { trackClick } = useCustomAnalytics();
  const [videoModal, setVideoModal] = useState(false);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const videoRef = useRef(),
    playerRef = useRef();

  const handleStartVideo = () => {
    if (!mainVideo) return;
    trackClick({ title: name, locationDetail: 'testimonial_card' });
    setVideoModal(true);
    setVideoPlaying(true);
  };

  return (
    <Box
      onClick={mainVideo ? handleStartVideo : undefined}
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',

        cursor: 'pointer',
        ':hover .video-play-button': {
          background: 'white',
          color: 'black',
        },

        ':hover .main-thumbnail': {
          transform: 'scale(1.1) translate3d(0,0,-400px)',
        },
      }}
    >
      {mainVideo && (
        <VideoModal
          aspectRatio="9/16"
          forwardSx={{ width: '100%', height: '90vh' }}
          videoModal={videoModal}
          setVideoModal={setVideoModal}
          videoRef={videoRef}
          locationDetail={'testimonial_card_video_modal'}
          setVideoPlaying={setVideoPlaying}
          playerRef={playerRef}
          mainVideo={mainVideo}
          videoPlaying={videoPlaying}
        />
      )}
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
          borderRadius: '1.6rem',
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          overflow: 'hidden',
        }}
      >
        {mainVideo || photo ? (
          <Box
            sx={{
              width: '100%',
              height: '0px',
              flexShrink: 0,
              position: 'relative',
              pb: 'calc(100% * .602)',
              overflow: 'hidden',
              isolation: 'isolate',
              flex: '0 1 auto',
            }}
          >
            <Box sx={{ height: '100%', width: '100%', position: 'absolute' }}>
              {mainVideo && (
                <CTAButton
                  // disabled={tabIndex === -1}
                  disableDisabledStyle={true}
                  className="video-play-button"
                  forwardSx={{
                    pointerEvents: 'none',
                    position: 'absolute',
                    bottom: ['2.4rem', '3.2rem'],
                    left: ['2.4rem', '3.2rem'],
                    zIndex: 1,
                  }}
                  baseColor="White"
                  size="Small"
                  variant="Outline"
                >
                  {buttonText}
                </CTAButton>
              )}

              {photo && (
                <div
                  className="main-thumbnail"
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    top: '0%',
                    width: '100%',
                    zIndex: -1,
                    transition: '.3s ease',
                  }}
                >
                  <ImgLoader
                    image={photo}
                    forwardSx={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }}
                  />
                </div>
              )}
            </Box>
          </Box>
        ) : null}
        <Flex
          sx={{
            backgroundColor: backgroundColor ?? 'cloud',
            width: '100%',
            height: '100%',
            gap: '1.6rem',
            p: ['2.4rem', '3.2rem'],
            pb: ['2.6rem', '3.2rem'],
            flexDirection: 'column',
          }}
        >
          {starRating && <StarRating averageScore={starRating} />}
          <Box
            sx={{ fontSize: '1.6rem', fontWeight: 500, lineHeight: '2.1rem', textTransform: 'uppercase' }}
            dangerouslySetInnerHTML={{ __html: bioTitle ?? '' }}
          />
          <Box
            sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 400, lineHeight: 1.5, p: { m: 0 } }}
            dangerouslySetInnerHTML={{ __html: bio?.childMarkdownRemark?.html ?? '' }}
          />
          <Flex sx={{ flexDirection: 'column' }}>
            <Text
              sx={{
                fontWeight: 'medium',
                fontSize: '1.4rem',
                lineHeight: 1,
                textTransform: 'uppercase',
              }}
            >
              {name}
            </Text>
            <Text
              sx={{
                fontWeight: 300,
                fontSize: '1.4rem',
                lineHeight: 1.5,
              }}
            >
              {tagline}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TestimonialCard;
