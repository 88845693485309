/* eslint-disable no-nested-ternary */
/* eslint-disable arrow-body-style */

import { Box } from 'theme-ui';
import React, { useEffect } from 'react';

// videoJS with MUX
import videojs from '@mux/videojs-kit';
import '@mux/videojs-kit/dist/index.css';
import { useMuxVideoAnalytics } from '~/hooks/utils/customAnalytics';

const AmbassadorVideo = React.memo(function AmbassadorVideo({
  mainVideo,
  videoRef,
  playerRef,
  videoPlaying,
  setVideoPlaying,
  videoLabel,
  aspectRatio = '16/9',
  forwardSx,
  locationDetail,
}) {
  const playerInitTime = Date.now();
  const handleVideoReady = async (player) => {
    player = playerRef.current;
    player.src({ src: `${mainVideo.video.playbackId}`, type: 'video/mux' });
    if (videoPlaying) {
      player.play().catch((err) => {
        console.error('video play error', err);
      });
    }

    player.on('ended', () => {
      setVideoPlaying(false);
    });
    player.on('play', (e) => {
      setVideoPlaying(true);
    });
  };

  useEffect(() => {
    if (!playerRef.current) {
      const video = videoRef.current;
      if (!video) return;
      const player = (playerRef.current = videojs(
        video,
        {
          timelineHoverPreviews: true,
          aspectRatio: aspectRatio.replace('/', ':'),
          plugins: {
            mux: {
              debug: false,
              data: {
                env_key: process.env.GATSBY_MUX_DATA, // required

                // Metadata
                player_name: 'Ambassador Player', // ex: 'My Main Player'
                player_init_time: playerInitTime, // ex: 1451606400000

                // Video Metadata
                video_id: mainVideo.video.playbackId,
                video_title: mainVideo.name,
              },
            },
          },
        },
        () => {
          handleVideoReady(player);
        }
      ));
    } else {
      const player = playerRef.current;
      player.src({ src: `${mainVideo.video.playbackId}`, type: 'video/mux' });
      player.mux.emit('videochange', {
        video_id: mainVideo.video.playbackId,
        video_title: mainVideo.name,
      });
      player.play().catch((err) => {
        player.play().catch((err) => {});
      });

      player.on('ended', () => {
        setVideoPlaying(false);
      });
      player.on('play', (e) => {
        const event = new CustomEvent('custom-event', {
          bubbles: true,
          detail: { category: 'ux_click', action: 'video_start', label: `video_start_${videoLabel}` },
        });
        e.target.dispatchEvent(event);
      });
    }
  }, [mainVideo, videoRef, playerRef]);

  useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  useMuxVideoAnalytics({
    playerRef: playerRef,
    id: mainVideo.id,
    title: mainVideo.name,
    locationDetail,
  });

  return (
    <Box
      sx={{
        position: 'relative',
        aspectRatio: aspectRatio,
        width: ['90vw', null, '80vw'],
        maxWidth: '1280px',
        maxHeight: '90vh',
        objectFit: 'cover',
        ...forwardSx,
      }}
    >
      <video
        ref={videoRef}
        className="video-js vjs-16-9 vjs-matrix"
        sx={{
          height: 'calc(100% + 4px) !important',
          marginBottom: '-1px',
          '.vjs-tech': {
            objectFit: 'cover',
            height: '100% !important',
            width: '100%',
          },
          'div.vjs-progress-control.vjs-control': {
            maxWidth: [null, null, 'calc(100% - 25rem)'],
          },
          aspectRatio: aspectRatio,
          // position: 'absolute !important',
          objectFit: 'cover !important',
        }}
        playsInline
        controls
        preload="auto"
      />
    </Box>
  );
});

export default AmbassadorVideo;
