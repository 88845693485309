import { Children, useState } from 'react';
import { Box, Container, Grid } from 'theme-ui';
import AnimatedSlidingToggle from '~/components/Generic/AnimatedSlidingToggle';
import CTAButtonWithWrapper from '~/components/Generic/CTAButtonWithWrapper';
import GenericSlider from '~/components/Generic/GenericSlider';
import useMatchMedia from '~/hooks/utils/use-match-media';

const ContentSlideShow = ({ children, length, type, loop, slidesToShow, textColor, forwardSx, tabs, CTA }) => {
  switch (type) {
    case 'slider':
      return (
        <ContentSlider
          length={length}
          loop={loop}
          slidesToShow={slidesToShow}
          textColor={textColor}
          forwardSx={forwardSx}
        >
          {children}
        </ContentSlider>
      );

    case '3-up slider':
    case '2-up slider':
      return (
        <GridContentSlider length={length} type={type.split(' ')?.[0]} forwardSx={forwardSx}>
          {children}
        </GridContentSlider>
      );
    case '3-across':
      return (
        <GridContentSlider
          length={length}
          forwardSx={{
            gridTemplateColumns: ['1fr', 'repeat(3, 1fr)'],
            px: [0, '2.4rem'],
            gap: ['1.6rem', '2.4rem'],
            pt: ['4rem', '5.6rem'],
            ...forwardSx,
          }}
        >
          {children}
        </GridContentSlider>
      );
    case 'P2 Featurette':
      return (
        <Box
          sx={{
            maxWidth: 'var(--max-width)',
            mx: 'auto',
            px: ['2.4rem', '12rem'],
          }}
        >
          {children}
        </Box>
      );
    case 'tabbed-section':
      return (
        <TabbedContainer tabs={tabs} CTA={CTA}>
          {children}
        </TabbedContainer>
      );
    default:
      return null;
  }
};

const GridContentSlider = ({ children, forwardSx }) => {
  return (
    <Container variant="fullWidth">
      <Grid
        sx={{
          mx: 'auto',
          maxWidth: 'var(--max-width)',
          gridTemplateColumns: '1fr',
          gap: ['1.6rem', '5.6rem'],
          ...forwardSx,
        }}
      >
        {children}
      </Grid>
    </Container>
  );
};

const TabbedContainer = ({ children, tabs, CTA }) => {
  const [activeTab, setActiveTab] = useState(tabs[1].value);
  const handleClick = (value) => {
    setActiveTab(value);
  };
  const currentActiveButtonIndex = tabs.findIndex((tab) => tab.value === activeTab);
  return (
    <Grid
      sx={{
        maxWidth: 'var(--max-width)',
        mx: 'auto',
        px: [0, '9.5rem'],
        pt: ['2.4rem', '4rem'],
        mb: ['2.4rem', '3.2rem'],
        placeItems: 'center',
        gap: ['2.4rem', '4rem'],
      }}
    >
      <AnimatedSlidingToggle
        tabs={tabs}
        currentActiveButtonIndex={currentActiveButtonIndex}
        handleClick={handleClick}
      />
      {Children.toArray(children)[currentActiveButtonIndex]}
      {CTA ? <CTAButtonWithWrapper callToAction={CTA} /> : null}
    </Grid>
  );
};

const ContentSlider = ({ length = 0, children, loop = false, slidesToShow, textColor, forwardSx = {} }) => {
  const mobile = useMatchMedia('mobile');
  const tablet = useMatchMedia('tablet');

  const sliderSettings = {
    slidesToShow: slidesToShow ?? (mobile && length > 1 ? 1 : tablet && length > 2 ? 2 : length > 3 ? 3 : length),
    slidesToScroll: slidesToShow ? 1 : mobile ? 1 : tablet ? 2 : 3,
    centerMode: true,
    centerPadding: '17px 0 24px',
    infinite: loop,
    speed: 900,
  };
  return (
    <GenericSlider
      sliderSettings={sliderSettings}
      length={length}
      arrowSx={{
        translate: '0 33%',
      }}
      forwardSx={{
        mx: 'auto',
        maxWidth: '1280px',
        pb: ['0.4rem', '0.8rem'],
        '.slick-list': { overflow: 'visible', ml: ['0', '3.2rem', '5.7rem'] },
        '.slick-slide': {
          position: 'relative',
          left: [null, '-.8rem', '-1.7rem'],
          maxWidth: length < 3 && ['100%', '43.7rem'],
          pr: ['1.6rem', '2.4rem'],
        },
        '.slick-track': {
          height: '100%',
          position: 'relative',
          display: 'grid',
          justifyContent: 'center',
          gridAutoFlow: 'column',
          '.slick-slide > div': { height: '100%' },
        },
        '.slick-dots > li > button': {
          backgroundColor: textColor ?? 'black',
        },
        '.slick-slider > button': {
          svg: {
            path: {
              stroke: textColor ?? 'black',
            },
          },
        },
        ...forwardSx,
      }}
    >
      {children}
    </GenericSlider>
  );
};

export default ContentSlideShow;
