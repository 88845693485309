import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
/**
 * A basic modal to show information to the user.
 * Currently  used for Klarna, Size Guide and Ambassador video
 */
const Modal = ({ children, isOpen, onDismiss, ariaLabel, forwardSx }) => {
  const [isVisible, setIsVisible] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (!isOpen) {
      setIsVisible(false);
      return;
    }

    timeoutRef.current = setTimeout(() => setIsVisible(true), 300);
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [isOpen]);

  return (
    <DialogOverlay
      isOpen={isOpen}
      sx={{
        position: 'fixed',
        inset: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isVisible ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
        zIndex: 20,
        transition: 'background-color 0.3s ease-in-out',
      }}
      onDismiss={onDismiss}
    >
      <DialogContent
        aria-label={ariaLabel}
        sx={{
          '--strokecolor': 'black',
          width: 'fit-content',
          maxWidth: ['88.5%', '100%'],
          mx: [0, '3rem'],
          backgroundColor: 'white',
          opacity: isVisible ? 1 : 0,
          transition: 'opacity 0.3s',
          overflow: 'hidden',
          position: 'relative',
          ...forwardSx,
        }}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  children: null,
};

export default Modal;
